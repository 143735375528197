.scrollToTop {
  padding: 10px;
}

.overRideTransition {
  color: #23527c;
}

.cyanButton {
  /* the class is called cyanButton, because the background color was cyan */
  background-color: #0053a6 !important;
  color: #fff;
  display: block;
  font-size: 20px;
  width: 100%;
  border: 0;
  max-width: 200px;
  padding: 10px;
  margin: 10px auto;
  text-align: center;
  /* font-weight: 600; */
  border-radius: 5px;
}

.longCyanButton {
  background-color: #0053a6 !important;
  font-size: 20px;
  color: #fff;
  margin: 10px auto;
  font-weight: 600;
  border-radius: 5px;
}

.greyButton {
  background-color: #737373;
  color: #fff;
  display: block;
  font-size: 20px;
  width: 100%;
  border: 0;
  max-width: 200px;
  padding: 10px;
  margin: 10px auto;
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
}

.cyanButton:hover,
.greyButton:hover,
.longCyanButton:hover {
  background-color: #023368 !important;
  color: #fff;
}

.text-justify {
  text-align: justify;
}

.formWrapper {
  background-color: #f5f5f5;
}

.formHeader {
  background-color: #0053a6;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 40px;
  padding: 4px 15px 12px;
  line-height: 48px;
}

.formBody {
  max-width: 1140px;
  padding: 1.5rem;
}

.formBody .blg-container.container-fluid {
  padding: 5px 0 0 0;
}

.formBody .blg-col-0 {
  display: none;
}

.formBody .form-group {
  padding-bottom: 24px !important;
}

.formBody h6,
.formBody .checkIConsent,
.formBody .form-check-label {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.formBody .form-check-label {
  padding: 0 1.5rem 0 0 !important;
}

.formBody .consent-font {
  font-size: 10px;
}

.formBody .telLink {
  font-size: 56px;
  line-height: 67.2px;
  color: #0053a6;
  text-decoration: none;
  text-align: center;
  font-weight: 400;
  display: block;
  margin: auto;
}

.formBody .telLink:hover {
  color: #23527c !important;
}


.formBody .btn-submit {
  padding: 6px;
  width: 200px;
  border-radius: 5px !important;
  background-color: #0053a6;
  font-size: 20px !important;
  line-height: 30px !important;
  font-weight: 400 !important;
}

.formBody .btn-submit:hover {
  background-color: #023368;
}

.formBody .btn-start {
  color: #fff !important;
}

nav.navbar {
  padding: 0.25rem 1rem;
}

.navbar-brand:first-of-type a {
  text-decoration: none !important;
}

.navbar-brand:first-of-type a:hover {
  text-decoration: underline !important;
}

.nav-item button.nav-link.active {
  color: #0053a6 !important;
}

button.btnApplyNow.btn.btn-primary {
  color: #0053a6 !important;
}

button.btnApplyNow.btn.btn-primary .textBlue,
button.btnApplyNow.btn.btn-primary h2.reducefontSize strong span,
button.btnApplyNow.btn.btn-primary h3.lbl-Apply strong {
  color: #0053a6 !important;
}

button.btnApplyNow.btn.btn-primary:hover,
button.btnApplyNow.btn.btn-primary:focus {
  background-color: #0053a6 !important;
}

button.btnApplyNow.btn.btn-primary:hover .textBlue,
button.btnApplyNow.btn.btn-primary:hover h2.reducefontSize strong span,
button.btnApplyNow.btn.btn-primary:hover h3.lbl-Apply strong,
button.btnApplyNow.btn.btn-primary:focus .textBlue,
button.btnApplyNow.btn.btn-primary:focus h2.reducefontSize strong span,
button.btnApplyNow.btn.btn-primary:focus h3.lbl-Apply strong {
  color: #fff !important;
}

.modal-header {
  background-color: transparent !important;
}

@media screen and (max-width: 991px) {

  .applyNowBody .blg-col-9 .checkIConsent,
  .applyNowBody .blg-col-9 .form-check-label {
    font-size: 11px !important;
  }

  .applyNowBody .blg-col-9 .form-check-label {
    padding: 0 1rem 0 0 !important;
  }
}

.applyNowBody .btn-start {
  color: #fff !important;
}

.applyNowBody .blg-container.container-fluid {
  padding-top: 0;
}

.applyNowBody .blg-col-ty {
  margin-top: -10px;
}

.container .pt-4 .col-10 p a {
  text-decoration: none !important;
}

.container .pt-4 .col-10 p a:hover {
  text-decoration: underline !important;
}

@media screen and (max-width: 1025px) {
  .navbar span.contactHead {
    display: block;
    font-size: small;
  }
}

@media screen and (max-width: 565px) {
  .formBody .telLink {
    font-size: 9.5vw !important;
  }
}

@media screen and (max-width: 516px) {
  .formBody textarea {
    min-height: 4rem !important;
  }
}

button#next-button.pull-right.btn-lg,
button#prev-button.pull-left.btn-lg {
  border-radius: 2px !important;
  line-height: 14px !important;
  padding: 9px 20px !important;
  height: fit-content !important;
  margin-top: 20px;
}

.sr-only {
  position: absolute;
  width: 0;
  height: 0;
}

@media screen and (min-width: 601px) and (max-width:991px) {
  .applyNowWrapper .btn-submit {
    height: 6vw;
    width: 20vw;
  }
}

@media screen and (min-width: 600px) and (max-width:699px) {
  .applyNowWrapper .btn-submit {
    font-size: 13px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .applyNowWrapper .btn-submit {
    padding: 7px 20px !important;
    font-size: 10px !important;


  }
}

.btn-success {
  background-color: #198754 !important;
  border-color: #198754 !important;
  margin-left: 8vw !important;
}

.btn-success:hover {
  background-color: #1c995f !important;
  border-color: #1c995f !important;
}

.modal-content {
  margin-top: 7rem;
}

@media (max-width: 767.8px) {

  /* Adjust the max-width to your specific breakpoint */
  img#equalImg {
    margin-bottom: 5rem !important;
    /* Apply the margin only for mobile views */
  }
}


@media screen and (max-width: 700px) {
  .formBody .btn-submit {
    width: 150px;
  }
  
  .borrowerLeadGeneration .btnAdjustDiv {
    left: auto!important;
    margin-left: auto!important;
    margin-right: auto!important;
    display: block!important;
  }
  .borrowerLeadGeneration .backBtnDiv {
    z-index: 10 !important;
}
  .borrowerLeadGeneration .btn-success {
    left: 30% !important;
    position: relative !important;
  }
  .borrowerLeadGeneration .btn-submit .btn-success {
    display: flex;
    display: flex!important;
    align-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }
}

@media screen and (max-width: 400px) {
  .formBody .btn-submit {
    width: 100px!important;
  }}
