.navbar-brand img {
  width:450px;
}

@media only screen and (min-width:320px) and (max-width:374px) {
    .navbar-brand img {
      width: 100%;
      max-width: 242px;
    }
    .socialHead {
      text-align: center;
    }
    .contactHead, .promoHead {
      display: none;
    }
    .nav-item {
      text-align: center;
    }
  }
  
  @media only screen and (min-width:375px) and (max-width:424px) {
    .navbar-brand img {
      /*height: 85px;*/
      width: 100%;
      max-width: 310px;
    }
    .socialHead {
        text-align: center;
    }
    .contactHead, .promoHead {
      display: none;
    }
    .nav-item {
          text-align: center;
    }
  }
  
  @media only screen and (min-width:425px) and (max-width:767.7px) {
    .navbar-brand img {
      /*height: 85px;*/
      width: 100%;
      max-width: 310px;
    }
    .socialHead {
      text-align: center;
    }
    .contactHead, .promoHead {
      display: none;
    }
    .nav-item {
      text-align: center;
    }
  }

  @media only screen and (min-width:768px) and (max-width:833px) {
    .navbar-brand img {
      /*height: 85px;*/
      width: 100%;
      max-width: 400px;
    }
    .socialHead {
      text-align: center;
    }
    .contactHead, .promoHead {
      display: none;
    }
    .nav-item {
      text-align: center;
    }
  }
  
  @media only screen and (min-width:768px) and (max-width:1023px) {
    .contactHead, .promoHead {
		display: none;
    }
  }
    
  /* @media only screen and (min-width:1025px) and (max-width:1340px) {
      .contactHead, .promoHead {
      display: none;
    }
  } */
  