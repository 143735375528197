@font-face {
  font-family: 'Cambria', 'Poppins-Regular';
  src: local('Poppins-Regular'), url(./fonts/poppins/Poppins-Regular.otf) format('opentype');
}
/*Medium version*/
@font-face {
  font-family: 'Cambria', 'poppins-black';
  src: url(./fonts/poppins/Poppins-Black.otf) format('opentype');
}
/*Medium version*/
@font-face {
  font-family: 'Cambria', 'poppins-medium';
  src: url(./fonts/poppins/Poppins-Medium.otf) format('opentype');
}
/*bold version*/
@font-face {
  font-family: 'Cambria', 'poppins-semibold';
  src: url(./fonts/poppins/Poppins-SemiBold.otf) format('opentype');
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');


.poppins-black {
  font-family: 'Open Sans', 'Montserrat', sans-serif,'Cambria','poppins-black', 'poppins',sans-serif;
}

.poppins-med {
  font-family: 'Open Sans', 'Montserrat', sans-serif,'Cambria','poppins-medium', 'poppins',sans-serif;
}

.poppins-bold {
  font-family: 'Open Sans', 'Montserrat', sans-serif,'Cambria','poppins-semibold', 'poppins',sans-serif;
}

body {
  margin: 0;
  
  font-family: 'Open Sans', 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Cambria', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'sans-serif' !important ; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Cambria', 'Courier New',
    monospace;
}

.textBlue {
  color: #0053a6;
}
.textBluePercentage {
  color: #0053a6;
  font-weight: 600;


}

.hidden {
  display: none !important;
}

.form-required{
  border:1px solid #eb191996 !important;
}

.rate-title-bg-color{
  background-color: #0053a6;
  margin-top: 8rem;
}

.contactus-banner-bg-color{
  background-color: #0053a6;
}

a{
  color: #0053a6!important;
}

a:hover, a:focus{
  color: #23527c!;
}

@media only screen and (min-width:768px) and (max-width: 833.7px){
.rate-title-bg-color{
  background-color: #0053a6;
  margin-top: 3.3rem;
}
}

@media only screen and (min-width:476px) and (max-width: 767.7px){
  .rate-title-bg-color{
    background-color: #0053a6;
    margin-top: 12rem;
  }
  }


  @media only screen and (min-width:381px) and (max-width: 475.7px){
    .rate-title-bg-color{
      background-color: #0053a6;
      margin-top: 11rem;
    }
    }
  

  @media only screen and (min-width:320px) and (max-width: 380.7px){
    .rate-title-bg-color{
      background-color: #0053a6;
      margin-top: 10rem;
    }
    }
