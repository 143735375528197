.multiImageBack {
    background-image: url(../components/images/city-skyline.jpg);          
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
}
  
  .responsive-iframe {
    display: flex;
    align-items: center!important;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: none;
  }

  .testimonials-row {
    height: 80%;
  }


@media only screen and (max-width:375px) {
    .widgetWrapper {
        background-image: none!important;
    }
    .multiImageBack {
        height: 45vh;
    }
    .bigPhone {
        font-size: 2.25em;
    }
}


@media only screen and (max-width:1299.97px) {
    .testimonials-row {
        height: 60%;
      }
}

@media only screen and (min-width:377px) and (max-width:424px) {
    .bigPhone {
        font-size: 2.5em;
    }
}

@media only screen and (min-width:425px) and (max-width:767px) {
    .bigPhone {
        font-size: 3em;
    }
}
